<template>
  <div>
    <div
      class="min-h-screen container mx-auto flex flex-col gap-y-5 justify-center items-center font-EffraR bg-white py-5"
    >
      <div class="grid gap-x-3 gap-y-8 grid-cols-1">
        <section id="doctors" class="flex flex-col gap-y-5">
          <h1 class="font-EffraM text-4xl text-dokBlue-ultra">
            Questions fréquentes dokpro
          </h1>

          <div
            v-for="(item, index) in sectionsPro"
            :key="index"
            class="bg-white border border-solid border-grayborder w-full py-4 px-2 rounded-cu flex flex-col"
            :class="choice.section === index ? 'gap-y-2' : 'gap-y-0'"
          >
            <div class="flex justify-between items-center gap-x-2">
              <h1 class="font-EffraR text-2xl text-labelGray">
                {{ item.name }}
              </h1>
              <svg
                @click="openSection(index)"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 9l6 6 6-6"
                />
              </svg>
            </div>
            <transition-group name="slide" class="flex flex-col gap-y-2">
              <template v-if="choice.section === index">
                <div
                  v-for="(qs, indexqs) in item.questions"
                  :key="indexqs"
                  class="flex flex-col gap-y-1 bg-grayTable p-3 rounded"
                >
                  <div class="flex flex-row justify-between gap-x-2">
                    <h3 class="text-lg font-EffraM">{{ qs.qs }}</h3>
                    <svg
                      @click="openQuestion(indexqs)"
                      class="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 9l6 6 6-6"
                      />
                    </svg>
                  </div>

                  <p
                    v-if="choice.qs === indexqs"
                    class="leading-loose font-EffraR"
                    v-html="qs.an"
                  ></p>
                </div>
              </template>
            </transition-group>
          </div>
        </section>

        <section id="patients" class="hidden flex-col gap-y-5">
          <h1 class="font-EffraM text-4xl text-rosDok-ultra">
            Questions fréquentes application patient
          </h1>

          <div
            v-for="(item, index) in sectionsPatient"
            :key="index"
            class="bg-white border border-solid border-grayborder w-full py-4 px-2 rounded-cu flex flex-col"
            :class="choicePatient.section === index ? 'gap-y-2' : 'gap-y-0'"
          >
            <div class="flex justify-between gap-x-2 items-center">
              <h1 class="font-EffraR text-2xl text-labelGray">
                {{ item.name }}
              </h1>
              <svg
                @click="openSectionPatient(index)"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 9l6 6 6-6"
                />
              </svg>
            </div>
            <transition-group name="slide" class="flex flex-col gap-y-2">
              <template v-if="choicePatient.section === index">
                <div
                  v-for="(qs, indexqs) in item.questions"
                  :key="indexqs"
                  class="flex flex-col gap-y-1 bg-grayTable p-3 rounded"
                >
                  <div class="flex flex-row justify-between gap-x-2">
                    <h3 class="text-lg font-EffraM">{{ qs.qs }}</h3>
                    <svg
                      @click="openQuestionPatient(indexqs)"
                      class="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 9l6 6 6-6"
                      />
                    </svg>
                  </div>

                  <p
                    v-if="choicePatient.qs === indexqs"
                    class="leading-loose font-EffraR"
                    v-html="qs.an"
                  ></p>
                </div>
              </template>
            </transition-group>
          </div>
        </section>
      </div>
    </div>

    <dok-footer></dok-footer>
  </div>
</template>

<style>
.slide-enter-active {
  transition: opacity 0.5s ease;
}

.slide-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<script>
const dokFooter = () => import("@/views/home/layouts/footer");

export default {
  components: { dokFooter },
  data() {
    return {
      sectionsPro: [
        {
          name: "Généralités dok.ma pro",
          questions: [
            {
              qs: "Qu'est ce que dok.ma ?",
              an: `dok.ma est une plateforme d'outils digitaux ergonomiques de santé conçue pour connecter les patients à l'ensemble du système médical.`
            },
            {
              qs: "Quelle est notre mission et notre vision?",
              an: `Notre mission est de rendre les soins de santé de qualité abordables et accessibles à tous les Marocains. <br /> C’est avec la vision d’un Maroc en meilleure santé que nous avons co-construit, avec nos praticiens pilotes, dok.ma pro afin de gérer leurs cabinets, améliorer la prise en charge, sécuriser leurs données, gagner en temps, en confort et bien plus encore.`
            },
            {
              qs: "Quelles fonctionnalités offre dok.ma pro? ",
              an: `
              Voici les fonctionnalités principales de dok.ma pro: 
              <br />
                <img src="/svg/infographic.svg" class="w-full" />
              <br />
              Inscrivez-vous pour les essayer gratuitement et en découvrir davantage. 
              `
            }
          ]
        },
        {
          name: "Création, activation et accès à votre compte professionnel",
          questions: [
            {
              qs: "Comment créer un compte pro sur dok.ma ?",
              an: `
                  Depuis la page d'accueil de dok.ma, cliquez sur le bouton "dok Pro pour les praticiens" en haut à droite, après sur "créer un compte" en bas de la page. <br />
                  Sur la page d'inscription, vous devez tout simplement remplir le formulaire et cliquer sur "créer votre compte".<br />
                   <img src="/screenshots/1.png" class="w-full" />
                  <br />
                  Vous recevrez ensuite un email avec un lien de vérification d'identité professionnelle pour activer votre compte, cette étape est obligatoire pour éviter toute fraude ou manquement éthique
              `
            },
            {
              qs: "Comment activer mon compte pro ?",
              an: `
                  Après votre inscription, vous recevrez un lien par email pour être rediriger vers une page afin de télécharger votre CIN et votre carte CPEM (ou tout autre document justificatif d'identité professionnelle).<br />
                  <img src="/screenshots/2.png" class="w-full" />
                  <br />
                  Cette étape est nécessaire pour éviter toute usurpation d'identité ou fraude et les données sont strictement confidentielles et protégées.<br />
                  Nous vous enverrons un email après vérification pour confirmer l'activation de votre compte sous un délai de 12 heures ouvrées.
              `
            },
            {
              qs: "Comment se connecter/déconnecter d'un compte patient ? ",
              an: `
                  Pour vous connecter, vous appuyez sur le bouton "dok Pro pour les praticiens", puis vous renseignez votre boite email et votre mot de passe dans les champs réservés à cet usage et cliquer sur "se connecter".
                  <br />
                  <img src="/screenshots/3.png" class="w-full" />
                  <br />
                  Pour vous déconnecter, il suffit d'ouvrir la barre latérale et de cliquer sur "se déconnecter".<br />
                  <img src="/screenshots/4.png" class="w-full" />
              `
            },
            {
              qs: "Comment récupérer un compte pro? (mot de passe oublié)",
              an: `
                  Pour réinitialiser votre mot de passe, il suffit de cliquer sur "mot de passe oublié" et de suivre les étapes.<br />
                  <img src="/screenshots/5.png" class="w-full" />
              `
            },
            {
              qs: "Comment changer mon adresse mail ? ",
              an: `
                  Pour des raisons de sécurité et pour assurer un bon fonctionnement de la plateforme, vous ne pouvez pas changer votre adresse mail sans nous contacter. 
                  <br />
                  Vous avez le choix entre ouvrir un ticket sur le module support, ou nous contacter sur le numéro 0708087770. 
                  <br />
                   <img src="/screenshots/6.png" class="w-full" />
              `
            },
            {
              qs: "Comment se connecter sur un compte utilisateur ? ",
              an: `
                  L'utilisateur se connecte sur la même page de connexion que le praticien. 
                  <br />
                  Il saisit son adresse mail et le mot de passe envoyé par SMS et il clique sur le bouton "se connecter". 
                  <br />
                  <img src="/screenshots/3.png" class="w-full" />
                  <br />
                  Une fois connecter, il peut procéder au changement de son mot de passe dans paramètres. 
              `
            }
          ]
        },
        {
          name: "Fonctionnalités dok.ma pro",
          questions: [
            {
              qs:
                "Comment compléter ou modifier mon profil public et le publier ?",
              an: `
                La saisie des informations de votre fiche praticien ou profil public est une étape obligatoire lors de votre 1ère connexion. Toutefois, si vous souhaitez faire des modifications par la suite, vous pouvez accéder à votre profil public en cliquant sur paramètres dans la barre latérale. 
                <br />
                 <img src="/screenshots/7.png" class="w-full" />
                <br />
                La publication de votre profil sur notre plateforme, ainsi que la mise à jour de vos données, se font automatiquement après chaque changement.            
              `
            },
            {
              qs: "Comment configurer mon agenda connecté? ",
              an: `
                La configuration de l'agenda se fait en deux étapes. D'abord il faut ajouter les motifs et leurs tarifs, après les motifs "nouvelle consultation" et "téléconsultation" sont configurés par défaut au moment où le praticien remplit son profil public. Ensuite, une fois que les motifs ajoutés, vous pouvez configurer vos horaires d'ouverture et mettre les motifs qui conviennent à chaque créneau.  
                <br />
                    <img src="/screenshots/8.png" class="w-full" />
                <br />
                Il est bien sûr possible de dupliquer un jour sur toute la semaine, et il faut également prendre en compte que le paramétrage de l'agenda sera dupliqué sur toutes les semaines à venir.         
              `
            },
            {
              qs: "Comment créer un rendez-vous?",
              an: `
                Pour ajouter un nouveau rendez-vous, vous pouvez soit cliquer sur le bouton "créer un nouveau rendez-vous" en haut à gauche dans l'onglet calendrier, soit cliquer sur le créneau horaire qui vous convient sur votre agenda puis remplir le formulaire. 
                <br />
                                    <img src="/screenshots/9.png" class="w-full" />
                <br />
                Le patient recevra automatiquement une notification concernant le rendez-vous que vous lui avez attribué. 
              `
            },
            {
              qs: "Comment bloquer un créneau horaire ?",
              an: `
                Vous pouvez accéder à l'option "bloquer un créneau" de la même façon que pour créer un nouveau rendez-vous, à la seule différence qu'il faut choisir l'onglet "bloquer créneau". Après, vous pouvez sélectionner la date et les heures que vous voudriez bloquer ou bien bloquer la journée entière. <br />
                     <img src="/screenshots/10.png" class="w-full" />

              `
            },
            {
              qs: "Comment annuler un rendez-vous ?",
              an: `
                Pour annuler un rendez-vous, il suffit de cliquer sur celui dans l'agende et de sélectionner "annuler le rendez-vous". Le patient recevra automatiquement une notification pour l'informer de l'annulation.
                 <br />
                 <img src="/screenshots/11.png" class="w-full" />
               
              `
            },
            {
              qs: "Comment reporter un rendez-vous ?",
              an: `
                Vous pouvez reporter un rendez-vous soit au moment de recevoir la notification de demande de rendez-vous en cliquant sur "changer horaire". Soit en cliquant sur le rendez-vous et en sélectionnant "reporter rendez-vous". Ou bien en glissant le rendez-vous sur l'agenda jusqu'au créneau voulu.
                <br />
                <img src="/screenshots/12.png" class="w-full" />
                <br />
                Peu importe la manière que vous choisissez pour reporter la visite, le patient sera automatiquement notifié et aura la possibilité d'accepter ou de refuser le nouveau rendez-vous.
              `
            },
            {
              qs: "Comment ajouter ou créer un nouveau patient ?",
              an: `
                Pour ajouter un patient qui a déja un compte dok.ma, il suffit de faire une recherche avec le nom, la CIN ou le numéro de téléphone dans le module de création de rendez-vous. 
                <br />
                <img src="/screenshots/13.png" class="w-full" />
                <br />
                Si le patient ne détient pas de compte dok.ma, vous pouvez toujours lui en créer un rapidement, en cliquant sur "créer un nouveau patient" soit dans le module de création de rendez-vous ou bien dans l'onglet patientèle. Ensuite, vous saisissez les informations demandées, le patient recevra automatiquement une notification SMS avec ces codes d'accès à son compte.
              `
            },
            {
              qs: "Comment accéder au carnet de santé d'un nouveau patient ?",
              an: `
                Le respect de la confidentialité s'inscrit au coeur de la charte de dok.ma, par conséquent le praticien doit envoyer une demande d'accès au carnet de santé à son nouveau patient afin qu'il puisse le visualiser, ce dernier a le droit d'accepter ou de refuser l'accès au praticien. 
                <br />
                 <img src="/screenshots/14.png" class="w-full" />
                <br />
                Pour envoyer la demande d'accès, vous pouvez soit cliquer sur le rendez-vous pris sur votre agenda, puis cliquer sur le bouton "demande d'accès au carnet de santé". Ou bien, vous pouvez aller à l'onglet consultation, cliquer sur "voir consultation" et envoyer votre demande depuis ce module.
              `
            },
            {
              qs: "Comment commencer une consultation ?",
              an: `
               Pour commencer une consultation, vous pouvez soit cliquer sur le rendez-vous pris sur votre agenda, puis cliquer sur le bouton "lancer la consultation". Ou bien, vous pouvez aller à l'onglet consultation et cliquer sur "voir consultation".
              `
            },
            {
              qs: "Comment activer la téléconsultation ?",
              an: `
                Pour activer la téléconsultation, allez sur paramètres, profil public, tarif puis optez pour la téléconsultation et choisissez le tarif à appliquer. 
                <br />
                <img src="/screenshots/16.png" class="w-full" />
                <br />
                Si vous voulez faire des téléconsultation gratuitement, mettez 0 dans la case.
              `
            },
            {
              qs: "Comment lancer une téléconsultation ?",
              an: `
                30min avant l'heure de la téléconsultation une icone de notification apparaitra automatiquement en haut à droite avec un compte à rebours. 
                <br />
                <img src="/screenshots/17.png" class="w-full" />
                <br />
                A la fin du décompte, un bouton "lancer la téléconsultation" s'affiche dans la notification. Cliquez dessus et l'appel sera initié.
              `
            },
            {
              qs: "Comment savoir si la téléconsultation a été payé ?",
              an: `
                Vous recevrez tout simplement une notification sur la dashboard dok.ma pro dès que le patient a effectué le paiement de la téléconsultation.
                <br /> 
                <img src="/screenshots/18.png" class="w-full" />
              `
            },
            {
              qs: "Qu'est ce que le dossier médical partagé (DMP) ?",
              an: `
                Le dossier médical partagé comprend l’ensemble des données du patient: les informations générales, le carnet de santé, les consultations, les documents et une note confidentielle. 
                Le praticien n'accèdent pas aux consultations et documents réalisés chez des confrères sauf si le patient l'autorise. Compte à la note confidentielle, elle est uniquement visible par le praticien qui l'a rédigé.
              `
            },
            {
              qs: "Comment accéder au dossier médical partagé ?",
              an: `
                Vous pouvez accéder au dossier médical en vous rendant à l'onglet patientèle et en cliquant sur le bouton "consulter le DMP". 
                <br />
                <img src="/screenshots/19.png" class="w-full" />
                <br />
                Le praticien ne peut bien sûr consulter que les données partagées entre lui et son patient.
              `
            },
            {
              qs: "Comment partager un document avec un patient ?",
              an: `
                Pour partager un document avec un patient, rendez-vous sur la consultation relatif au fichier que vous voulez partager, puis sur documents générés. 
                <br />
                Cliquez sur ajouter document, importez le fichier, remplissez les informations nécessaires et finalement envoyer le au patient. 
              `
            },
            {
              qs: "Comment partager une consultation avec un confère ?",
              an: `
                Afin de partager une consultation avec un confrère, il faut se rendre au DMP du patient, puis sur l'onglet "consultation", ensuite vous cliquez sur "partager avec un praticien" pour envoyer la demande d'autorisation au patient. 
                <br />
                <img src="/screenshots/20.png" class="w-full" />
                <br />
                Une fois que le patient aurait accepté, vous cliquez encore une fois sur "partager avec un praticien", puis vous sélectionnez le nom du confrère avant d'envoyer la consultation.
                <br />
                <img src="/screenshots/21.png" class="w-full" />
              `
            },
            {
              qs: "Pourquoi et comment définir les statuts des consultations ?",
              an: `
                La définition du statut vous aidera à trier les patients et à déterminer ceux qui honorent pas leurs pas le rendez-vous pour une meilleure gestion de votre agenda.
                <br />
                <img src="/screenshots/22.png" class="w-full" />
                <br />
                Pour définir le statut du rendez-vous, il suffit de cliquer sur ce dernier sur l'agenda, puis de choisir dans la liste déroulante un statut.
              `
            },
            {
              qs: "Comment marche la messagerie sécurisée ?",
              an: `La messagerie dok.ma pro a été élaboré de façon à ce que le praticien ne soit pas dérangé par des messages envoyés en rafale pas les patients. C'est à dire que le praticien est le seul à pouvoir initier une conversation, et que le patient ne peut que répondre. Une fois la conversation fermée, il ne sera plus possible au patient d'envoyer de messages. 
                    <br />
                    <img src="/screenshots/23.png" class="w-full" />
              `
            },
            {
              qs: "Comment personnaliser mon ordonnance ? ",
              an: `Dès que vous remplissez votre profil public, un entête et pied de page est automatiquement générés. Toutefois, vous pouvez toujours modifier les informations sur l'ordonnance ou ajouter un logo dans l'onglet "paramètres", "personnalisation d'ordonnance".
                                  <br />
                    <img src="/screenshots/24.png" class="w-full" />`
            },
            {
              qs: "Qu'est ce que l'onglet utilisateurs ? ",
              an: `
                Cette fonctionnalité vous permet d'ajouter deux collaborateurs (assistante, infirmière...etc.) pour vous aider à gérer votre cabinet. 
                <br />
                Vous pouvez leur donner accès au calendrier, sa configuration, la patientèle, et le support selon vos convenances. 
                <br />
                <img src="/screenshots/25.png" class="w-full" />
                <br />
                Pour en savoir plus sur la  protection des données dans un compte utilisateur, consultez la section " à quelles données ont accès les utilisateurs ?".
              `
            },
            {
              qs: "Comment ajouter un utilisateur ? ",
              an: `
                Dans l'onglet utilisateur, cliquez sur le bouton "créer un nouvel utilisateur", saisissez les informations demandées puis choisissez les fonctionnalités auxquelles votre collaborateur aura accès.
                <br />
                L'utilisateur recevra un SMS avec les accès au compte. 
                <br />
                <img src="/screenshots/26.png" class="w-full" />
                <br />
                Pour en savoir plus, merci de consulter la section "Comment se connecter sur un compte utilisateur ?".
              `
            },
            {
              qs: "Comment supprimer un utilisateur ? ",
              an: `Il suffit de se rendre à l'onglet "utilisateurs", et de cliquer sur le bouton supprimer puis de confirmer.
                <br />
                <img src="/screenshots/27.png" class="w-full" />
              `
            }
          ]
        },
        {
          name: "Abonnement dok.ma pro et paiements des téléconsultations",
          questions: [
            {
              qs: "Comment fonctionne mes 30 jours de gratuité ?",
              an: `
                Pour que vous puissiez vous rendre compte de l'efficacité de dok.ma, nous avons le plaisir de vous proposer 30 jours d'essai gratuit avec l'ensemble des fonctionnalités premium pour vous faire découvrir tout le potentiel de notre ecosystème unique.
                <br />
                Pour profiter de ces 60 jours gratuits, il suffit de créer un compte. Aucune carte bancaire ne vous sera demander et aucun prélèvement ne sera effectué.
              `
            },
            {
              qs: "Comment m'abonner à dok.ma pro premium ?",
              an: `
                Pour souscrire un abonnement pro premium, il suffit d'aller à l'onglet "paramètres", "paiements et abonnement", ensuite sur "abonnement".
                <br />
                En cliquant sur le bouton "abonnez-vous", vous serez redirigez vers une page de paiement afin de choisir le plan qui vous convient et de saisir les données nécessaires pour s'abonner à dok.ma pro premium.
                <br />
                NB: Notre plateforme respecte des normes très strictes de protection de données, pour en savoir plus rendez-vous à la section sécurité et confidentialité.
              `
            },
            {
              qs: "A quel moment mon abonnement est prelevé ?",
              an: `
                Au début de chaque mois anniversaire, vous serez débité sur votre carte bancaire avec envoi automatique de votre facture sur votre adresse mail.
              `
            },
            {
              qs: "Comment résilier mon abonnement ?",
              an: `
                Vous pouvez résilier votre abonnement à tout moment, il faut simplement  l'onglet "paramètres", "paiements et abonnement", ensuite sur "abonnement". 
                <br />
                En cliquant sur "se désabonner 
                <br />
                dok.ma pro premium restera actif 
              `
            },
            {
              qs: "Comment mes patients payent leurs téléconsultations ?",
              an: `
                Les patients doivent obligatoirement régler leurs téléconsultations au préalable. Le montant que vous aurez programmé sera prélevé sur la carte bancaire du patient.
              `
            },
            {
              qs: "Comment savoir si la téléconsultation a été payé ?",
              an: `
                  Vous recevrez tout simplement une notification sur la dashboard dok.ma pro dès que le patient a effectué le paiement de la téléconsultation.
                  <br />
                  Si toutefois le paiement n'a pas été effectué, vous recevrez un message d'alerte avant de lancer votre vidéo consultation.
              `
            },
            {
              qs: "Comment recevoir les paiements des téléconsultations ?",
              an: `
              A la fin de chaque mois, nous effectuerons un virement de la totalité des paiements des téléconsultations du mois. 
              <br />
              Merci de bien vouloir renseigner vos informations bancaires et de veiller à ce qu'elles soient exactes pour éviter toute irrégularité.
              `
            },
            {
              qs:
                "Comment savoir combien de téléconsultation vous avez éffectué ?",
              an: `
                  Un historique des téléconsulations est disponible dans le module "paramètres", onglet "paiement et abonnement". 
                  <br />
                  Vous y trouverez la date et l'heure de la téléconsultation, le prix, le nom du patient et si le paiement a bien été effectué par ce dernier ou pas.
              `
            }
          ]
        },
        {
          name: "Sécurité, protection et confidentialité",
          questions: [
            {
              qs:
                "Quel protocole est utlisé pour la sécurité, protection et confidentialité des données ?",
              an: `
                Les données sont sécurisées à l'aide de RBAC, TLS/SSL, Encryption at rest, CSFLE. <br/>

                C'est à dire:<br/>

                -Accès aux données limité en fonction du rôle de l'utilisateur. <br/>
                -Données cryptées pour être transportées sur une connexion réseau de confiance.<br/>
                -Données cryptées sur le disque et restent cryptées.<br/>
                -Données sensibles cryptées à tout moment sur l'hôte de la base de données et en transit sur le réseau.<br/>
                -Blockchain privée ultra-sécurisée.<br/>
                -Accessibilité serveur à 99,9%.
              `
            },
            {
              qs:
                "Comment est géré la sécurité, la protection et la confidentialité des données ?",
              an: `
                Nous disposons de la meilleure équipe d'experts pour garantir la sécurité des données. Tout le trafic de données qui passe par nos serveurs est analysé et suivi en temps réel. Toutes les données sont sauvegardées plusieurs fois, dans des lieux sécurisés au Maroc.
                <br />
                L'utilisation de normes strictes en matière de protection et de transfert des données est garantie en permanence. Nos systèmes, dotés d’intelligence artificielle, gardent toujours un œil sur toutes les modifications, et les écarts sont signalés immédiatement 24/7.
                <br />
                Et finalement, afin de conserver le secret médical et le secret des données, dok.ma, traitant ces données très sensibles,  n’a pas accès aux données de santé des patients. dok.ma ne peut, en aucun cas, divulguer quelconque information, sans l’accord du patient. Que ce soit le nombre de rendez-vous pris, le traitement, ou tout autre type de données.
                <br />
                Merci de consulter notre politique de confidentialité pour en savoir plus.
              `
            },
            {
              qs:
                "Est ce que dok.ma peut communiquer mes informations à un tiers ?",
              an: `
                NON - Selon La Loi 09-08 sur la protection des données personnelles, Article 11 : Neutralité des effets
                <br />
                Aucune décision de justice impliquant une appréciation sur le comportement d’une personne ne peut avoir pour fondement un traitement automatisé de données à caractère personnel destiné à évaluer certains aspects de sa personnalité.
                <br />
                Aucune autre décision produisant des effets juridiques à l’égard d’une personne ne peut être prise sur le seul fondement d’un traitement automatisé de données destiné à définir le profil de l’intéressé ou à évaluer certains aspects de sa personnalité.
              `
            },
            {
              qs: "A quelles données ont accès les utilisateurs ?",
              an: `
                  Les utilisateurs, les collaborateurs de votre cabinet, ont accès aux fonctionnalités que vous leur attribuez, mais cela n'inclut pas les données sensibles des patients. 
                  <br />
                  Par exemple: si vous donnez accès à un utilisateur à la patientèle, il aura uniquement le droit de consulter les informations générales sans pouvoir accéder au carnet de santé, les consultations, la note confidentielles et les documents partagés.    
              `
            },
            {
              qs:
                "Est-ce que les patients peuvent partager leurs données avec d'autres praticiens ?",
              an: `
                Oui, les patients ont une totale liberté sur leurs données, et sur leurs consultations antérieures, pour cela il suffit qu'ils autorisent l'accès à tout ou une partie du DMP, au partcien de leur choix. 
                <br />
                Exemple : Le patient se rend chez son cardiologue, il doit faire un IRM, le patient aura donc la possibilité de partager tout ou une partie de son DMP au radiologue chez qui il fera son IRM.
              `
            }
          ]
        }
      ],
      sectionsPatient: [
        {
          name: "Généralités sur l'application dok.ma",
          questions: [
            {
              qs: "Qu'est ce que dok.ma ? ",
              an: `dok.ma est une plateforme d'outils digitaux ergonomiques de santé conçue pour connecter les patients à l'ensemble du système médical.`
            },
            {
              qs: "Quelle est notre mission et notre vision? ",
              an: `
                Notre mission est de rendre les soins de santé de qualité abordables et accessibles à tous les Marocains. 
                <br />
                C’est avec la vision d’un Maroc en meilleure santé que nous avons conçu l'application dok.ma qui comprend un panel de fonctionnalités afin de réduire l'attente pour les rendez-vous, bénéficier d'un suivi médical adapté, garder un historique des données et rester en contact avec son praticien.
              `
            },

            {
              qs: "Quelles fonctionnalités offre l'application dok.ma ? ",
              an: `L'application dok.ma offre gratuitement une multitude de fonctionnalités aux patients. les principales sont les suivantes:`
            }
          ]
        },
        {
          name:
            "Création, activation, accès et suppression de votre compte patient",
          questions: [
            {
              qs: "Comment créer un compte patient sur dok.ma ?",
              an: `
                Vous pouvez créer un compte sur notre page web en suivant les étapes. Un compte web vous permettera uniquement de prendre rendez-vous. 
                <br />
                Pour profiter pleinement de tous les services qu'offre dok.ma aux patients gratuitement, nous vous conseillons de télécharger notre application. Une fois que vous l'avez téléchargé, cliquez sur s'inscrire puis suivez les étapes. Quand vous aurez complété votre inscription, vous recevrez un code par sms pour activer votre compte.
                <br />
                Si c'est l'un de de nos praticiens partenaires qui vous a créé un compte sur dok.ma lors d'une consultation, vous recevrez automatiquement un sms avec votre mot de passe. Téléchargez notre application et connectez-vous directement.
                <br />
                N.B: Il est fortement recommandé de compléter les informations manquantes sur votre profil, ainsi que votre carnet de santé!
              `
            },
            {
              qs: "Quoi faire si vous n'avez pas reçu de sms d'activation ?",
              an: `
                Si vous n'avez pas reçu un sms avec un code d'activation, cliquez sur renvoyer le code. 
                <br />
                usieurs tentatives, vous n'avez toujours pas reçu un sms d'activation, prière de nous contacter via ce formulaire ou en nous appelant au 0708082222. Nous reviendrons vers vous dans les plus brefs délais. `
            },
            {
              qs: "Comment se connecter/déconnecter d'un compte patient ? ",
              an: `Pour vous connecter: cliquez sur "Se connecter" , communiquez votre adresse mail et votre mot de passe, puis confirmez.<br />Pour vous déconnecter: allez sur votre profil en cliquant sur votre photo en haut à droite, puis séléctionnez "se déconnecter" tout en bas.`
            },
            {
              qs: "Comment récupérer un compte patient ? (mot de passe oublié)",
              an: `Pour réinitialiser votre mot de passe, il suffit de cliquer sur "mot de passe oublié" et de suivre les étapes.`
            },
            {
              qs: "Puis-je supprimer un compte patient ?",
              an: `
                  Oui, vous avez la possibilité de supprimer votre profil. Cependant, les données cryptées enregistrées sont conservées pendant 10 ans à des fins d'étude sur votre profil anonyme.<br /> 
                  Ces données restent totalement confidentielles et anonymes, afin de conserver vos données en toute sécurité. <br />
                  Pour la partie praticien (documents et informations partagées à un praticien) restent dans le profil des praticiens, vous devez faire la demande à chaque praticien de supprimer les documents vous concernant, il sera obligé de supprimer ces données sous 30 jours ouvrés.`
            },
            {
              qs: "Comment supprimer un compte patient ?",
              an: `Vous pouvez demander la suppression de votre compte patient et la non-conservation de vos données via ce formulaire, le délai moyen de traitement est de 30 jours ouvrés. `
            }
          ]
        },
        {
          name: "Fonctionnalités dok.ma pour les patients",
          questions: [
            {
              qs: "Comment rechercher un praticien ?",
              an: `
                Pour trouver sur dok.ma un praticien de médecin moderne ou alternaive; rendez-vous sur la barre de recherche de la page d'accueil et utilisez les filtres qui vous conviennent (nom, spécialité, ville, disponibilité...etc.). 
              `
            },
            {
              qs: "Comment prendre rendez-vous chez un praticien ?",
              an: `
                Une fois la recherche faite et le praticien choisi, vous pouvez consulter la fiche du praticien en cliquant sur le bouton "prendre rendez-vous", ensuite vous suivez les étapes afin de sélectionner un motif et choisir un créneau disponible qui vous convient. 
                <br />
                Votre demande sera automatiquement envoyée au praticien, et vous recevrez par la suite un sms ou une notification pour vous informer sur le statut de votre rendez-vous.
              `
            },
            {
              qs: "Comment déplacer ou annuler un rendez-vous ? ",
              an: `
                Vous ne pourrez pas déplacer un rendez-vous, mais par contre vous pouvez l'annuler et en prendre un autre. Pour cela il suffit de se rendre sur "Agenda",cliquer sur le menu défilant et de séléctionner "Annuler le rendez-vous". 
                <br />
                Pour en prendre un autre chez le même praticien: cliquez encore une fois sur le menu défilant, séléctionnez "Prendre rendez-vous" et suivez les étapes.
                <br />
                Pour prendre rendez-vous chez un praticien différent, veuillez consulter la section "Comment prendre rendez-vous chez un professionnel de santé ? ".
              `
            },
            {
              qs: "Comment partager un document ?",
              an: ``
            },
            {
              qs: "Comment programmer mon pilulier ?",
              an: ``
            },
            {
              qs: "Pourquoi et comment remplir mon carnet de santé ?",
              an: ``
            }
          ]
        },
        {
          name: "Sécurité et confidentialité",
          questions: [
            {
              qs:
                "Quel protocole est utlisé pour la sécurité, protection et confidentialité des données ?",
              an: `
                Les données sont sécurisées à l'aide de RBAC, TLS/SSL, Encryption at rest, CSFLE. 
                <br />
                C'est à dire:
                <br />
                -Accès aux données limité en fonction du rôle de l'utilisateur. <br />
                -Données cryptées pour être transportées sur une connexion réseau de confiance.<br />
                -Données cryptées sur le disque et restent cryptées.<br />
                -Données sensibles cryptées à tout moment sur l'hôte de la base de données et en transit sur le réseau.<br />
                -Blockchain privée ultra-sécurisée.<br />
                -Accessibilité serveur à 99,9%.
              `
            },
            {
              qs:
                "Comment est géré la sécurité, la protection et la confidentialité des données ?",
              an: `
                Nous disposons de la meilleure équipe d'experts pour garantir la sécurité des données. Tout le trafic de données qui passe par nos serveurs est analysé et suivi en temps réel. Toutes les données sont sauvegardées plusieurs fois, dans des lieux sécurisés au Maroc.
                <br />
                L'utilisation de normes strictes en matière de protection et de transfert des données est garantie en permanence. Nos systèmes, dotés d’intelligence artificielle, gardent toujours un œil sur toutes les modifications, et les écarts sont signalés immédiatement 24/7.
                <br />
                Et finalement, afin de conserver le secret médical et le secret des données, dok.ma, traitant ces données très sensibles,  n’a pas accès aux données de santé des patients. dok.ma ne peut, en aucun cas, divulguer quelconque information, sans l’accord du patient. Que ce soit le nombre de rendez-vous pris, le traitement, ou tout autre type de données.
                <br />
                Merci de consulter notre politique de confidentialité pour en savoir plus.
              `
            },
            {
              qs:
                "Est ce que dok.ma peut communiquer mes informations à un tiers ?",
              an: `
                NON - Selon La Loi 09-08 sur la protection des données personnelles, Article 11 : Neutralité des effets
                <br />
                Aucune décision de justice impliquant une appréciation sur le comportement d’une personne ne peut avoir pour fondement un traitement automatisé de données à caractère personnel destiné à évaluer certains aspects de sa personnalité.
                <br />
                Aucune autre décision produisant des effets juridiques à l’égard d’une personne ne peut être prise sur le seul fondement d’un traitement automatisé de données destiné à définir le profil de l’intéressé ou à évaluer certains aspects de sa personnalité.
              `
            },
            {
              qs:
                "Est-ce que les patients peuvent partager leurs données avec d'autres praticiens ?",
              an: `
                Oui, les patients ont une totale liberté sur leurs données, et sur leurs consultations antérieures, pour cela il suffit qu'ils autorisent l'accès à tout ou une partie du DMP, au partcien de leur choix. 
                <br />
                Exemple : Le patient se rend chez son cardiologue, il doit faire un IRM, le patient aura donc la possibilité de partager tout ou une partie de son DMP au radiologue chez qui il fera son IRM.
              `
            }
          ]
        }
      ],
      choice: {
        section: null,
        qs: null
      },
      choicePatient: {
        section: null,
        qs: null
      }
    };
  },
  methods: {
    openSection(index) {
      if (this.choice.section === index) {
        this.choice.qs = null;
        this.choice.section = null;
        return;
      }
      this.choice.qs = null;
      this.choice.section = index;
    },
    openQuestion(index) {
      if (this.choice.qs === index) {
        this.choice.qs = null;
        return;
      }

      this.choice.qs = index;
    },
    openSectionPatient(index) {
      if (this.choicePatient.section === index) {
        this.choicePatient.qs = null;
        this.choicePatient.section = null;
        return;
      }
      this.choicePatient.qs = null;
      this.choicePatient.section = index;
    },
    openQuestionPatient(index) {
      if (this.choicePatient.qs === index) {
        this.choicePatient.qs = null;
        return;
      }

      this.choicePatient.qs = index;
    }
  }
};
</script>
